<template>
  <div class="sf-header__icons-desktop">
    <span class="search-icon-wrapper">
      <component
        @click="openFilters"
        :is="headerColor === 'white' ? 'BlackSearchIcon' : 'WhiteSearchIcon'"
        class="header-icons__icons__icon search_icon"
        alt="search-icon"
      />
      <div class="top-search">
        <SearchBar
          :is-search-open="isSearchOpen"
          @set-is-open="isSearchOpen = $event"
          @set-search-results="productSearchResults = $event"
          @set-search-word="searchWord = $event"
        />
        <SearchResults
          v-if="isSearchOpen"
          :visible="isSearchOpen"
          :search-results="productSearchResults"
          @close="isSearchOpen = false"
          :term="searchWord"
        />
      </div>
    </span>
    <div
      class="country-text desktop-only"
      :class="headerColor === 'white' ? 'black' : 'white'"
    >
      <span v-if="getCountryText">
        {{ getCountryText }}
      </span>
      <component
        :is="
          headerColor === 'white'
            ? 'BlackLangSelectorIcon'
            : 'WhiteLangSelectorIcon'
        "
        class="header-icons__icons__icon lg"
        @click="toggleCountryModal"
        alt="lang-selector"
      />
    </div>
    <div class="bag-icon-container wishlist-bag">
      <component
        :is="
          headerColor === 'white' ? 'BlackWishlistIcon' : 'WhiteWishlistIcon'
        "
        class="header-icons__icons__icon wishlist-bag__icon desktop-only"
        @click="$emit('wishlistSidebarClick')"
        alt="wishlist-icon"
      />
      <nuxt-link
        :to="fixUrlForCurrentLocale('/wishlist')"
        class="smartphone-only"
        aria-label="wishlist-link"
      >
        <component
          :is="
            headerColor === 'white' ? 'BlackWishlistIcon' : 'WhiteWishlistIcon'
          "
          class="header-icons__icons__icon wishlist-bag__icon"
          alt="wishlist-icon"
        />
      </nuxt-link>
      <SfBadge
        v-if="wishlistTotalItems"
        class="sf-header_menu-image filled-bag"
      >
        {{ wishlistTotalItems }}
      </SfBadge>
    </div>
    <div class="bag-icon-container">
      <component
        :is="headerColor === 'white' ? 'BlackBagIcon' : 'WhiteBagIcon'"
        class="header-icons__icons__icon desktop-only"
        @click="$emit('cartSidebarClick')"
        alt="bag-icon"
      />
      <nuxt-link
        :to="fixUrlForCurrentLocale('/cart')"
        class="smartphone-only"
        aria-label="cart-link"
      >
        <component
          :is="headerColor === 'white' ? 'BlackBagIcon' : 'WhiteBagIcon'"
          class="header-icons__icons__icon"
          alt="bag-icon"
        />
      </nuxt-link>
      <SfBadge v-if="cartTotalItems" class="sf-header_menu-image filled-bag">
        {{ cartTotalItems }}
      </SfBadge>
    </div>
    <div class="sf-header__icons-desktop__account desktop-only">
      <component
        @click="handleAccountClick"
        :is="headerColor === 'white' ? 'BlackUserIcon' : 'WhiteUserIcon'"
        class="header-icons__icons__icon"
        alt="account-icon"
      />
      <span
        v-if="userName"
        class="sf-header__icons-desktop__account__name"
        :class="`sf-header__icons-desktop__account__name-${headerColor}`"
      >
        {{ userName }}
      </span>
    </div>
    <div class="search-overly" @click="removeFilters" :visible="isSearchOpen" />
  </div>
</template>

<script>
import { SfButton, SfBadge, SfOverlay } from '@storefront-ui/vue';
import {
  ref,
  useContext,
  useRouter,
  computed,
  onMounted,
  defineComponent,
} from '@nuxtjs/composition-api';
import { wishlistGetters, useUser } from '@gemini-vsf/composables';
import { useWishlist, useUiState, useMarketsComposable } from '~/composables';
import {
  BlackSearchIcon,
  BlackLangSelectorIcon,
  BlackBagIcon,
  BlackUserIcon,
  BlackWishlistIcon,
  WhiteSearchIcon,
  WhiteLangSelectorIcon,
  WhiteWishlistIcon,
  WhiteBagIcon,
  WhiteUserIcon,
} from '~/components/General/icons';
import { useCurrentLocale } from '~/composables/useCurrentLocale';

export default defineComponent({
  name: 'HeaderIconsDesktop',
  components: {
    SfButton,
    SfBadge,
    BlackSearchIcon,
    BlackLangSelectorIcon,
    BlackBagIcon,
    BlackUserIcon,
    BlackWishlistIcon,
    WhiteSearchIcon,
    WhiteLangSelectorIcon,
    WhiteWishlistIcon,
    WhiteBagIcon,
    WhiteUserIcon,
    SfOverlay,
    SearchBar: () => import('~/components/Header/SearchBar/SearchBar.vue'),
    SearchResults: () =>
      import(
        /* webpackPrefetch: true */ '~/components/Header/SearchBar/SearchResults.vue'
      ),
  },
  props: {
    cartTotalItems: {
      type: Number,
    },
    wishlistTotalItems: {
      type: Number,
    },
    headerColor: {
      type: String,
      default: 'white',
    },
  },
  setup() {
    const router = useRouter();
    const { app } = useContext();
    const { currentMarket } = useMarketsComposable();
    const { toggleSearchModal, toggleCountryModal, toggleLoginModal } =
      useUiState();
    const { wishlist, loadWishlist } = useWishlist();
    const wishlistItems = computed(() =>
      wishlistGetters?.getItems(wishlist?.value)
    );
    const { isAuthenticated, user } = useUser();

    const isSearchOpen = ref(false);
    const productSearchResults = ref(null);
    const searchWord = ref('');
    const totalItems = computed(() => wishlistItems?.value?.length);
    const userName = computed(() =>
      isAuthenticated.value ? user.value?.firstname : ''
    );

    const addFilters = () => {
      const bodyEle = document.querySelectorAll('body');
      bodyEle.length > 0 && bodyEle[0].classList.add('search-box');
    };
    const openFilters = () => {
      addFilters();
    };
    const removeFilters = () => {
      const bodyEle = document.querySelectorAll('body');
      bodyEle.length > 0 && bodyEle[0].classList.remove('search-box');
    };
    const closeFilters = () => {
      removeFilters();
    };

    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'my-account' }));
      } else {
        toggleLoginModal();
      }
    };

    onMounted(() => {
      loadWishlist({
        pageSize: 48,
        customQuery: isAuthenticated.value
          ? {
              customerWishlist: 'customerWishlistCustom',
            }
          : {
              wishlist: 'wishlistCustom',
            },
      });
    });

    const getCountryText = computed(() => {
      return currentMarket.value?.localeValue || '';
    });

    const { fixUrlForCurrentLocale } = useCurrentLocale();

    return {
      userName,
      getCountryText,
      toggleSearchModal,
      isAuthenticated,
      wishlistGetters,
      handleAccountClick,
      totalItems,
      toggleCountryModal,
      computed,
      isSearchOpen,
      closeFilters,
      addFilters,
      openFilters,
      removeFilters,
      productSearchResults,
      searchWord,
      fixUrlForCurrentLocale,
    };
  },
});
</script>

<style lang="scss" scoped>
.header-icons__icons__icon.search_icon {
  min-height: 20px;
}
.header-icons__icons__icon.wishlist-bag__icon {
  min-height: 20px;
}
.header-icons__icons__icon {
  min-height: 20px;
  min-width: 20px;
}
.sf-header__icons-desktop {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 25px;
  width: min-content;
  grid-row-gap: 0;
  align-items: center;
  @include for-mobile {
    display: flex;
    grid-column-gap: 0;
  }
  &__account {
    display: flex;
    align-items: center;
    &__name {
      color: white;
      font-size: 0.8125rem;
      margin-left: 0.25rem;
      font-family: var(--barlow-regular);
      text-transform: uppercase;
      &-white {
        color: #161615;
      }
    }
  }
  .country-text {
    display: flex;
    align-items: flex-end;
    font-family: var(--barlow-medium);
    color: #161615;
    font-size: 18px;
    text-transform: uppercase;
    &.white {
      color: #fff;
    }
    svg {
      margin-left: 5px;
    }
  }
  .header-icons__icons__icon {
    &:hover {
      cursor: pointer;
    }
  }
  .search-icon-wrapper {
    max-height: 18px;
    padding-right: 20px;
    border-right: 1px solid var(--c-dark-grey);
    @media (max-width: 768px) {
      border: 0;
      padding-right: 0;
    }
  }
  .bag-icon-container {
    position: relative;
    height: 1.25rem;
    &.wishlist-bag {
      min-width: 20px;
      .wishlist-bag__icon {
        min-width: 20px;
      }
    }
    @media (max-width: 768px) {
      margin-left: 10px;
      &.wishlist-bag {
        margin-right: 5px;
      }
    }
    .filled-bag {
      @include input-label;
      position: absolute;
      left: 13px;
      top: -6px;
      height: 17px;
      width: 17px;
      padding: 0;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 3.125rem;
      background-color: black;
      margin: 0;
    }
  }
}
</style>

<style lang="scss">
.search-overly {
  display: none;
  position: fixed;
  left: 0;
  top: 100px;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  z-index: 22;
}
.top-search {
  display: none;
  position: fixed;
  width: 100%;
  left: 0;
  background: rgba(255, 255, 255, 0.97);
  top: 100px;
  z-index: 222;
  .sf-input__error-message {
    min-height: auto;
    height: auto;
    padding: 0;
    line-height: inherit;
  }
  @media (max-width: 1023px) {
    top: 81px;
    overflow: auto;
  }
  @media (max-width: 768px) {
    height: calc(100vh - 80px);
  }
  .sf-mega-menu-column__content {
    padding: 0;
  }
  .sf-input__wrapper {
    @include desktop-boxed;
    margin: 0 auto;
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 10px 2.5rem;
    @media (max-width: 1023px) {
      padding: 10px 15px;
    }
    .sf-input__bar {
      display: none !important;
    }
    #search {
      max-width: 300px;
      width: 300px;
      margin-left: auto;
      border: 0.5px solid rgba(22, 22, 21, 1);
      height: 50px;
      color: rgba(157, 157, 157, 1);
      font-family: var(--barlow-regular);
      padding: 17px 17px;
      outline: none !important;
      text-transform: uppercase;
      @media (max-width: 768px) {
        width: 100%;
        min-width: 100%;
        width: 100%;
      }
    }
  }
  .sf-mega-menu {
    width: 100%;
    margin: 0 auto;
    position: static !important;
    height: auto;
    background: none;
    .before-results {
      width: 100%;
    }
    .before-results__paragraph {
      margin: 0;
      color: rgba(157, 157, 157, 1);
      font-family: var(--barlow-regular);
      font-size: 16px;
      text-transform: uppercase;
      text-align: center;
      padding: 5px 0;
    }
    .sf-mega-menu__content {
      @include desktop-boxed;
      margin: 0 auto;
      padding: 0 2.5rem;
    }
  }
  .sf-search-bar {
    width: 100%;
    border: 0;
    height: auto;
    display: block;
    margin: 0;
    .sf-search-bar__button {
      display: none;
    }
  }
}
.search-box {
  overflow: hidden;
  .sf-header-custom_container {
    background: rgba(255, 255, 255, 0.97) !important;
  }
  .search-overly {
    display: block;
  }
  .top-search {
    display: block;
  }
}
</style>
