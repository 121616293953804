import { CartItem } from '@gemini-vsf/composables';
import { useContext } from '@nuxtjs/composition-api';

export const cartHelpers = () => {
  const {
    $vsf: {
      $gemini: { config },
    },
  } = useContext();
  const moveGiftToBottomSortFn = (a: CartItem, b: CartItem) => {
    const divoGiftGrn = config['divoGiftGrn'];
    if (a.product.sku === divoGiftGrn) {
      return 1;
    }
    if (b.product.sku === divoGiftGrn) {
      return -1;
    }
    return 0;
  };

  return {
    moveGiftToBottomSortFn,
  };
};
