import { useContext } from '@nuxtjs/composition-api';

export const useCurrentLocale = () => {
  const {
    i18n: {
      localeProperties: { code },
    },
  } = useContext();
  const fixUrlForCurrentLocale = (relativeLink: string): string => {
    let link = relativeLink;
    if (link.startsWith('http')) {
      return link;
    }
    if (relativeLink.includes(`/${code}`)) {
      link = link.replaceAll(`/${code}`, '');
    }
    return '/' + code + link;
  };
  return { fixUrlForCurrentLocale };
};
