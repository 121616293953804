export function productBrandUrl(product, localeCode) {
  const getBrand = product?.brand_attribute?.label?.toLowerCase() || '';
  const filteredCategories = product.categories.filter((category) => category.url_path.includes('/'));
  const longestCategory = filteredCategories
    ?.map((c) => ({
      urlArray: c.url_path?.split('/') ?? [],
      name: c?.name ?? '',
      url: c?.url_path ?? '',
    }))
    .reduce((a, b) => (a?.url?.length > b?.url?.length ? a : b));
  return longestCategory?.urlArray?.[0]
    ? `/${longestCategory.urlArray[0]}/designer${localeCode === 'en' ? 's' : ''}/${getBrand.toLowerCase().replace(/ /g, '-').replace(/&/g, 'e')}`
    : '';
}
