// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Context } from '@nuxt/types';
import cookieNames from '~/enums/cookieNameEnum';
import { hasAuthorizationError } from '~/helpers/util';
import { magentoStoreviewCodesMapping } from '~/helpers/magentoConfig/storeviewMapping';

export default ({ app, route, redirect }: Context) => {
  const path = route.fullPath?.split('/')[1];
  magentoStoreviewCodesMapping.forEach((element) => {
    if (path === element.magentoStoreViewCode) {
      const mCode = `/${element.magentoStoreViewCode}`;
      const gCode = `/${element.geminiCode}`;
      redirect(301, `${route.fullPath.replace(mCode, gCode)}`);
    }
  });
  if (route.name?.toLowerCase().includes('homepage')) {
    const regex = /homepage\/?/gi;
    let newRoute = route.fullPath.replace(regex, '');
    if (newRoute.at(-1) === '/') {
      newRoute = newRoute.slice(0, Math.max(0, route.fullPath.length - 1));
    }
    if (newRoute === route.fullPath) return;
    redirect(301, newRoute);
  }
  if (hasAuthorizationError(app.$cookies.get(cookieNames.customerCookieName))) {
    app.$cookies.remove(cookieNames.customerCookieName);
    app.$cookies.remove(cookieNames.cartCookieName);
    app.$cookies.remove(cookieNames.segmentsCookieName);
    app.$cookies.set(cookieNames.messageCookieName, {
      message: app.i18n.t('You are not authorized, please log in.'),
      type: 'warning',
      icon: null,
      persist: false,
      title: null,
    });
  }
};
