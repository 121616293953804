import { Category } from '@gemini-vsf/api-client';
import { AgnosticCategoryTree, AgnosticFacet, useVSFContext } from '@vue-storefront/core';
import { useRoute, useRouter } from '@nuxtjs/composition-api';
import { categoryGetters } from '@gemini-vsf/composables';
import { categoryHasDefaultSorting } from '~/helpers/plp/defaultSortingCategories';

const useUiHelpers = () => {
  const route = useRoute();
  const router = useRouter();
  const { query } = route.value;

  const {
    $gemini: { config: geminiConfig },
  } = useVSFContext();
  const availableFilters = new Set(geminiConfig.facets.available);

  const reduceFilters = (query) => (prev, curr) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const makeArray = Array.isArray(query[curr]) || !availableFilters.has(curr);
    return {
      ...prev,
      [curr]: makeArray ? query[curr] : [query[curr]],
    };
  };

  const getFiltersDataFromUrl = (onlyFilters) =>
    Object.keys(query)
      .filter((f) => (onlyFilters ? availableFilters.has(f) : !availableFilters.has(f)))
      // eslint-disable-next-line unicorn/prefer-object-from-entries
      .reduce(reduceFilters(query), {});

  const getFacetsFromURL = (category = '') => {
    const defaultSort = category && categoryHasDefaultSorting(category) ? '' : 'price_DESC';
    return {
      filters: getFiltersDataFromUrl(true),
      itemsPerPage: Number.parseInt(query.itemsPerPage as string, 10) || 14,
      page: Number.parseInt(query.page as string, 10) || 1,
      sort: (query.sort as string) || defaultSort,
      term: query.term as string,
    };
  };

  const changeSearchTerm = (term: string) => term;

  const getCatLink = (category: Category): string => categoryGetters.getSlug(category);

  const getAgnosticCatLink = (category: AgnosticCategoryTree): string => categoryGetters.getSlug(category);

  const changeSorting = async (sort: string) => {
    await router.push({ query: { ...query, sort } });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const changeFilters = async (filters: any) => {
    const filtersDataFromUrl = getFiltersDataFromUrl(false);
    if (filtersDataFromUrl?.openGroups && !filters?.openGroups) {
      delete filtersDataFromUrl.openGroups;
    }
    if (Object.keys(filtersDataFromUrl).includes('page')) {
      delete filtersDataFromUrl.page;
    }
    // force on_sale filter on gender filters
    await (Object.keys(filters).includes('cats_ids') &&
    (filters.cats_ids.includes('64b6a78cc7450153417fb807') ||
      filters.cats_ids.includes('64b6a78bc0c8b2b842fcf2ca') ||
      filters.cats_ids.includes('651ec96505915dd6bf6f726e'))
      ? router.push({
          query: {
            ...filtersDataFromUrl,
            ...filters,
            on_sale: true,
          },
        })
      : router.push({
          query: {
            ...filtersDataFromUrl,
            ...filters,
          },
        }));
  };

  const changeItemsPerPage = async (itemsPerPage: number) => {
    await router.push({
      query: {
        ...getFiltersDataFromUrl(false),
        itemsPerPage,
      },
    });
  };

  const setTermForUrl = async (term: string) => {
    await router.push({
      query: {
        ...getFiltersDataFromUrl(false),
        term: term || undefined,
      },
    });
  };

  const isFacetColor = (facet: AgnosticFacet): boolean => facet.id === 'color';

  const isFacetCheckbox = (): boolean => false;

  return {
    getFacetsFromURL,
    getCatLink,
    getAgnosticCatLink,
    changeSorting,
    changeFilters,
    changeItemsPerPage,
    setTermForUrl,
    isFacetColor,
    isFacetCheckbox,
    changeSearchTerm,
  };
};

export default useUiHelpers;
