var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cart-mini",attrs:{"id":"cart"}},[_c('SfSidebar',{directives:[{name:"e2e",rawName:"v-e2e",value:('sidebar-cart'),expression:"'sidebar-cart'"}],staticClass:"sf-sidebar--right",attrs:{"visible":_vm.isCartSidebarOpen,"title":" "},on:{"close":_vm.toggleCartSidebar},scopedSlots:_vm._u([{key:"circle-icon",fn:function(ref){
var close = ref.close;
return [_c('div',{staticClass:"close-icon",on:{"click":close}},[_c('svg',{attrs:{"width":"23","height":"23","viewBox":"0 0 23 23","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1.414 1.414L21.414 21.414","stroke":"#0F0F0F","stroke-width":"2","stroke-linecap":"round"}}),_vm._v(" "),_c('path',{attrs:{"d":"M21.414 1.414L1.414 21.414","stroke":"#0F0F0F","stroke-width":"2","stroke-linecap":"round"}})])])]}},{key:"content-top",fn:function(){return [_c('h2',[_vm._v(_vm._s(_vm.$t('My Cart')))]),_vm._v(" "),(_vm.totalItems)?_c('SfProperty',{staticClass:"sf-property--large cart-summary",attrs:{"name":_vm.$t('Total articles'),"value":_vm.totalItems}}):_vm._e()]},proxy:true},{key:"content-bottom",fn:function(){return [_c('transition',{attrs:{"name":"sf-fade"}},[(_vm.totalItems)?_c('div',[_c('CustomButton',{staticClass:"cart-sidebar__go-to-cart",attrs:{"layout":"second","is-full-width":true},on:{"click":function($event){return _vm.toggleSidebarAndNavigate('/cart')}}},[_vm._v("\n            "+_vm._s(_vm.$t('GO TO CART'))+"\n          ")]),_vm._v(" "),_c('CustomButton',{attrs:{"layout":"first","is-full-width":true},on:{"click":function($event){return _vm.toggleSidebarAndNavigate(
                _vm.isAuthenticated
                  ? '/checkout/shipping'
                  : '/checkout/user-account'
              )}}},[_vm._v("\n            "+_vm._s(((_vm.$t('GO TO CHECKOUT')) + " (" + (_vm.$fc(_vm.totals.total)) + ")"))+"\n          ")])],1):_c('div',[_c('SfButton',{staticClass:"sf-button--full-width sf-btn-blue",on:{"click":_vm.toggleCartSidebar}},[_vm._v("\n            "+_vm._s(_vm.$t('Torna allo shopping'))+"\n          ")])],1)])]},proxy:true}])},[_vm._v(" "),_vm._v(" "),_c('transition',{attrs:{"name":"sf-collapse-top","mode":"out-in"}},[_c('div',{staticClass:"notifications"},[(!_vm.isLoaderVisible)?_c('SfNotification',{attrs:{"visible":_vm.visible,"title":"Sei sicuro?","message":_vm.$t('Sei sicuro di voler rimuovere questo articolo dal carrello?'),"type":"secondary"},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('div',{staticClass:"button-wrap"},[_c('SfButton',{staticClass:"sf-button_remove_item",on:{"click":function($event){return _vm.actionRemoveItem(_vm.tempProduct)}}},[_vm._v("\n                "+_vm._s(_vm.$t('Si'))+"\n              ")]),_vm._v(" "),_c('SfButton',{on:{"click":function($event){_vm.visible = false}}},[_vm._v("\n                "+_vm._s(_vm.$t('Annulla'))+"\n              ")])],1)]},proxy:true},{key:"close",fn:function(){return [_c('div')]},proxy:true},{key:"icon",fn:function(){return [_c('SfIcon',{staticStyle:{"display":"none"}})]},proxy:true}],null,false,2194254253)}):_vm._e()],1)]),_vm._v(" "),(_vm.isLoaderVisible)?_c('SfLoader',{attrs:{"loading":_vm.isLoaderVisible}},[_c('div')]):_vm._e(),_vm._v(" "),_c('transition',{attrs:{"name":"sf-fade","mode":"in-out"}},[(_vm.totalItems && _vm.products)?_c('div',{key:"my-cart",staticClass:"my-cart"},[_c('div',{staticClass:"collected-product-list"},_vm._l((_vm.products),function(product){return _c('div',{key:_vm.getCartItemListKey(product),staticClass:"cart-item-list",on:{"click":function($event){({ product: product })}}},[_c('div',{staticClass:"cart-item-media"},[_c('figure',[_c('client-only',[_c('img',{attrs:{"src":_vm.cartGetters.getItemImage(product)
                        ? _vm.$device.isDesktop
                          ? ((_vm.cartGetters.getItemImage(product)) + "?w=200")
                          : ((_vm.cartGetters.getItemImage(product)) + "?w=400")
                        : '',"alt":"Image"},on:{"error":_vm.handleImageError}})]),_vm._v(" "),_c('img',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"src":_vm.cartGetters.getItemImage(product)
                      ? ((_vm.cartGetters.getItemImage(product)) + "?w=400")
                      : '',"alt":"Image"},on:{"error":_vm.handleImageError}})],1)]),_vm._v(" "),_c('div',{staticClass:"cart-item-details"},[_c('div',{staticClass:"cart-item-content"},[_c('div',{staticClass:"cart-item-sku"},[_c('p',[_vm._v(_vm._s(_vm.productSKU(product)))])]),_vm._v(" "),_c('nuxt-link',{staticClass:"cart-item-brand",attrs:{"to":_vm.fixUrlForCurrentLocale(
                      _vm.getProductBrandUrl(product.product)
                    )}},[_vm._v("\n                  "+_vm._s(_vm.productBrandName(product.product))+"\n                ")]),_vm._v(" "),_c('nuxt-link',{staticClass:"cart-item-name",attrs:{"to":_vm.fixUrlForCurrentLocale(_vm.buildProductUrl(product.product))},on:{"click":_vm.toggleCartSidebar}},[_vm._v("\n                  "+_vm._s(_vm.cartGetters.getItemName(product))+"\n                ")]),_vm._v(" "),_c('div',{staticClass:"cart-item-configuration"},[(
                      _vm.getAttributes(product).length > 0 &&
                      _vm.cartGetters.getItemSku(product) !== '3419047_UNI'
                    )?_c('div',_vm._l((_vm.getAttributes(product)),function(attr,index){return _c('SfProperty',{key:index,attrs:{"name":_vm.$t(attr.option_label),"value":_vm.$t(attr.value_label)}})}),1):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"cart-item-button"},[(!_vm.isGiftProduct(product))?_c('div',{staticClass:"select-qty"},[_c('SfSelect',{attrs:{"label":"","required":false,"valid":false,"disabled":false,"value":_vm.cartGetters.getItemQty(product).toString(),"placeholder":""},on:{"input":function($event){return _vm.updateCartItemQty(product, $event)}}},_vm._l((product.product.only_x_left_in_stock),function(item){return _c('SfSelectOption',{key:item,attrs:{"value":item}},[_vm._v(_vm._s(item)+"\n                    ")])}),1)],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"cart-item-remove"},[(!_vm.isGiftProduct(product))?_c('SfPrice',{attrs:{"regular":_vm.cartGetters.getItemPrice(product).regular,"special":_vm.cartGetters.getItemPrice(product).special,"product":product,"handle-cart-discounts":""}}):_vm._e(),_vm._v(" "),_c('a',{on:{"click":function($event){return _vm.removeCartTrackGTM(product)}}},[_vm._v("\n                    "+_vm._s(_vm.$t('Remove'))+"\n                  ")])],1)])])])}),0)]):_c('div',{key:"empty-cart",staticClass:"empty-cart"},[_c('div',{staticClass:"empty-cart__banner"},[_c('SfHeading',{staticClass:"empty-cart__heading",attrs:{"title":_vm.$t('Your shopping cart is empty'),"level":2,"description":_vm.$t(
                'It looks like you haven\'t added any items to the bag yet. Start shopping to fill it out.'
              )}})],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }