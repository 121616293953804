<template>
  <div id="cart" class="cart-mini">
    <!-- title must be not empty to display mini cart header -->
    <SfSidebar
      v-e2e="'sidebar-cart'"
      :visible="isCartSidebarOpen"
      title=" "
      class="sf-sidebar--right"
      @close="toggleCartSidebar"
    >
      <template #circle-icon="{ close }">
        <div class="close-icon" @click="close">
          <svg
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.414 1.414L21.414 21.414"
              stroke="#0F0F0F"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              d="M21.414 1.414L1.414 21.414"
              stroke="#0F0F0F"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </div>
      </template>
      <template #content-top>
        <h2>{{ $t('My Cart') }}</h2>
        <SfProperty
          v-if="totalItems"
          class="sf-property--large cart-summary"
          :name="$t('Total articles')"
          :value="totalItems"
        />
      </template>
      <transition name="sf-collapse-top" mode="out-in">
        <div class="notifications">
          <SfNotification
            v-if="!isLoaderVisible"
            :visible="visible"
            title="Sei sicuro?"
            :message="
              $t('Sei sicuro di voler rimuovere questo articolo dal carrello?')
            "
            type="secondary"
          >
            <template #action>
              <div class="button-wrap">
                <SfButton
                  class="sf-button_remove_item"
                  @click="actionRemoveItem(tempProduct)"
                >
                  {{ $t('Si') }}
                </SfButton>
                <SfButton @click="visible = false">
                  {{ $t('Annulla') }}
                </SfButton>
              </div>
            </template>
            <template #close>
              <div />
            </template>
            <template #icon>
              <SfIcon style="display: none" />
            </template>
          </SfNotification>
        </div>
      </transition>
      <SfLoader v-if="isLoaderVisible" :loading="isLoaderVisible">
        <div />
      </SfLoader>
      <transition name="sf-fade" mode="in-out">
        <div v-if="totalItems && products" key="my-cart" class="my-cart">
          <div class="collected-product-list">
            <div
              v-for="product in products"
              :key="getCartItemListKey(product)"
              class="cart-item-list"
              @click="({ product })"
            >
              <div class="cart-item-media">
                <figure>
                  <client-only>
                    <img
                      @error="handleImageError"
                      :src="
                        cartGetters.getItemImage(product)
                          ? $device.isDesktop
                            ? `${cartGetters.getItemImage(product)}?w=200`
                            : `${cartGetters.getItemImage(product)}?w=400`
                          : ''
                      "
                      alt="Image"
                    />
                  </client-only>
                  <img
                    @error="handleImageError"
                    :src="
                      cartGetters.getItemImage(product)
                        ? `${cartGetters.getItemImage(product)}?w=400`
                        : ''
                    "
                    alt="Image"
                    v-show="false"
                  />
                </figure>
              </div>
              <div class="cart-item-details">
                <div class="cart-item-content">
                  <div class="cart-item-sku">
                    <p>{{ productSKU(product) }}</p>
                  </div>
                  <nuxt-link
                    class="cart-item-brand"
                    :to="
                      fixUrlForCurrentLocale(
                        getProductBrandUrl(product.product)
                      )
                    "
                  >
                    {{ productBrandName(product.product) }}
                  </nuxt-link>
                  <nuxt-link
                    @click="toggleCartSidebar"
                    class="cart-item-name"
                    :to="
                      fixUrlForCurrentLocale(buildProductUrl(product.product))
                    "
                  >
                    {{ cartGetters.getItemName(product) }}
                  </nuxt-link>

                  <div class="cart-item-configuration">
                    <div
                      v-if="
                        getAttributes(product).length > 0 &&
                        cartGetters.getItemSku(product) !== '3419047_UNI'
                      "
                    >
                      <SfProperty
                        v-for="(attr, index) in getAttributes(product)"
                        :key="index"
                        :name="$t(attr.option_label)"
                        :value="$t(attr.value_label)"
                      />
                    </div>
                  </div>
                </div>
                <div class="cart-item-button">
                  <div class="select-qty" v-if="!isGiftProduct(product)">
                    <SfSelect
                      label=""
                      :required="false"
                      :valid="false"
                      :disabled="false"
                      :value="cartGetters.getItemQty(product).toString()"
                      placeholder=""
                      @input="updateCartItemQty(product, $event)"
                    >
                      <SfSelectOption
                        :key="item"
                        :value="item"
                        v-for="item in product.product.only_x_left_in_stock"
                        >{{ item }}
                      </SfSelectOption>
                    </SfSelect>
                  </div>
                  <div class="cart-item-remove">
                    <SfPrice
                      v-if="!isGiftProduct(product)"
                      :regular="cartGetters.getItemPrice(product).regular"
                      :special="cartGetters.getItemPrice(product).special"
                      :product="product"
                      handle-cart-discounts
                    />
                    <a @click="removeCartTrackGTM(product)">
                      {{ $t('Remove') }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else key="empty-cart" class="empty-cart">
          <div class="empty-cart__banner">
            <SfHeading
              :title="$t('Your shopping cart is empty')"
              :level="2"
              class="empty-cart__heading"
              :description="
                $t(
                  'It looks like you haven\'t added any items to the bag yet. Start shopping to fill it out.'
                )
              "
            />
          </div>
        </div>
      </transition>
      <template #content-bottom>
        <transition name="sf-fade">
          <div v-if="totalItems">
            <CustomButton
              class="cart-sidebar__go-to-cart"
              layout="second"
              :is-full-width="true"
              @click="toggleSidebarAndNavigate('/cart')"
            >
              {{ $t('GO TO CART') }}
            </CustomButton>
            <CustomButton
              layout="first"
              :is-full-width="true"
              @click="
                toggleSidebarAndNavigate(
                  isAuthenticated
                    ? '/checkout/shipping'
                    : '/checkout/user-account'
                )
              "
            >
              {{ `${$t('GO TO CHECKOUT')} (${$fc(totals.total)})` }}
            </CustomButton>
          </div>
          <div v-else>
            <SfButton
              class="sf-button--full-width sf-btn-blue"
              @click="toggleCartSidebar"
            >
              {{ $t('Torna allo shopping') }}
            </SfButton>
          </div>
        </transition>
      </template>
    </SfSidebar>
  </div>
</template>

<script>
import {
  SfLoader,
  SfNotification,
  SfHeading,
  SfButton,
  SfProperty,
  SfQuantitySelector,
  SfIcon,
} from '@storefront-ui/vue';
import {
  computed,
  defineComponent,
  ref,
  useRouter,
  useContext,
  onMounted,
} from '@nuxtjs/composition-api';
import { useUser, cartGetters } from '@gemini-vsf/composables';
import _debounce from 'lodash.debounce';
import {
  useUiState,
  useUiNotification,
  useUrlHelpers,
  useCart,
} from '~/composables';
import { useI18n } from '~/helpers/hooks/usei18n';
import SfSidebar from '~/components/storefront-ui/organisms/SfSidebar.vue';
import SfPrice from '~/components/storefront-ui/atoms/SfPrice/SfPrice.vue';
import SfSelect from '@storefront-ui/vue/src/components/molecules/SfSelect/SfSelect.vue';
import { CustomButton } from '~/components/General/FormElements';
import gtmHelper from '~/helpers/gtm/gtmHelper';
import { useCurrentLocale } from '~/composables/useCurrentLocale';
import { productBrandUrl } from '~/helpers/url/brand';

export default defineComponent({
  name: 'CartSidebar',
  components: {
    CustomButton,
    SfLoader,
    SfNotification,
    SfSidebar,
    SfIcon,
    SfButton,
    SfHeading,
    SfProperty,
    SfPrice,
    SfQuantitySelector,
    SfSelect,
  },
  setup() {
    const trans = useI18n();
    const { isCartSidebarOpen, toggleCartSidebar } = useUiState();
    const router = useRouter();
    const { app, $vsf } = useContext();
    const { divoGiftGrn } = $vsf.$gemini.config;
    const { removeFromCartPush } = gtmHelper();
    const {
      cart,
      removeItem,
      load: loadCart,
      loading,
      updateItemQty,
    } = useCart();
    const { isAuthenticated } = useUser();
    const { send: sendNotification, notifications } = useUiNotification();
    const products = computed(() => cartGetters.getItems(cart.value));
    const totals = computed(() => cartGetters.getTotals(cart.value));
    const totalItems = computed(() => cartGetters.getTotalItems(cart.value));
    const getAttributes = (product) => product?.configurable_options || [];
    const visible = ref(false);
    const isLoaderVisible = ref(false);
    const tempProduct = ref();
    const { buildProductUrl } = useUrlHelpers();
    const localeCode = app.i18n.localeProperties.localeValue;

    const { fixUrlForCurrentLocale } = useCurrentLocale();
    const productSKU = ({ product }) => product?.value?.merchant_sku;

    onMounted(async () => {
      if (!cart.value) {
        await loadCart();
      }
    });

    const sendToRemove = ({ product }) => {
      if (notifications.value.length > 0) {
        notifications.value[0].dismiss();
      }

      visible.value = true;
      tempProduct.value = product;
    };

    const actionRemoveItem = async (product) => {
      isLoaderVisible.value = true;
      const success = await removeItem(product);
      isLoaderVisible.value = false;
      visible.value = false;
      removeFromCartPush(product);
      if (success) {
        sendNotification({
          id: Symbol('product_removed'),
          message: `${cartGetters.getItemName(product)} ${trans.t(
            'successfully removed from your cart'
          )}`,
          type: 'success',
          icon: 'check',
          persist: false,
          title: 'Product removed',
        });
      }
    };

    const updateCartItemQty = async (product, quantity) => {
      isLoaderVisible.value = true;
      await updateItemQty(product, quantity);
      isLoaderVisible.value = false;
    };

    const getCartItemListKey = (cartItem) => {
      if (cartItem?.configurable_options?.length > 0) {
        const variantKey = cartItem?.configurable_options
          .map(
            (option) =>
              `${option?.configurable_product_option_uid || ''}-${
                option?.configurable_product_option_value_uid || ''
              }`
          )
          .join('-');
        return `${cartItem.product?.merchant_sku}-${variantKey}`;
      }
      return `${cartItem.product?.merchant_sku}`;
    };

    const toggleSidebarAndNavigate = async (path) => {
      toggleCartSidebar();
      await router.push(app.localePath(path));
    };

    const isGiftProduct = (cartItem) => cartItem?.product?.sku === divoGiftGrn;

    return {
      trans,
      localeCode,
      sendToRemove,
      isGiftProduct,
      actionRemoveItem,
      loading: computed(() => !!loading.value),
      isAuthenticated,
      products,
      removeItem,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      updateCartItemQty: _debounce(
        (...params) => updateCartItemQty(...params),
        1000
      ),
      isCartSidebarOpen,
      notifications,
      visible,
      isLoaderVisible,
      tempProduct,
      toggleCartSidebar,
      totals,
      totalItems,
      cartGetters,
      getAttributes,
      sendNotification,
      productSKU,
      buildProductUrl,
      getCartItemListKey,
      toggleSidebarAndNavigate,
      fixUrlForCurrentLocale,
    };
  },
  methods: {
    productBrandName(product) {
      return product?.brand_attribute?.label?.toLowerCase() || '';
    },
    getProductBrandUrl(product) {
      return productBrandUrl(product, this.localeCode);
    },
    handleImageError(event) {
      event.target.src = '/icons/placeholder.svg';
    },
    removeCartTrackGTM(product) {
      this.sendToRemove({ product });
    },
  },
});
</script>

<style lang="scss" scoped>
#cart {
  --sidebar-z-index: 5;
  --overlay-z-index: 3;
  @include for-desktop {
    & > * {
      --sidebar-bottom-padding: var(--spacer-base);
      --sidebar-content-padding: var(--spacer-base);
    }
  }
}
.close-icon {
  cursor: pointer;
}
.notifications {
  position: fixed;
  right: 20px;
  top: 20px;
  margin-left: -350px;
  z-index: 99999;
  .sf-notification {
    padding: 20px;
    .button-wrap {
      margin-top: 15px;
      display: flex;
      column-gap: 15px;
    }
  }
}
.my-cart {
  flex: 1;
  display: flex;
  flex-direction: column;

  &__total-items {
    margin: 0;
  }

  &__total-price {
    --price-font-size: var(--font-size--xl);
    --price-font-weight: var(--font-weight--medium);
    margin: 0 0 var(--spacer-base) 0;
  }
}
.empty-cart {
  --heading-description-margin: 0 0 var(--spacer-xl) 0;
  --heading-title-margin: 0 0 var(--spacer-xl) 0;
  --heading-title-color: var(--c-primary);
  --heading-title-font-weight: var(--font-weight--semibold);
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;

  &__banner {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }

  &__heading {
    padding: 0 var(--spacer-base);
  }

  &__image {
    --image-width: 16rem;
    margin: 0 0 var(--spacer-2xl) 7.5rem;
  }

  @include for-desktop {
    --heading-title-font-size: var(--font-size--xl);
    --heading-title-margin: 0 0 var(--spacer-sm) 0;
  }
}
.collected-product-list {
  flex: 1;
}
.collected-product {
  margin: 0 0 var(--spacer-sm) 0;

  &__properties {
    margin: var(--spacer-xs) 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    flex: 2;

    &:first-child {
      margin-bottom: 8px;
    }
  }

  &__actions {
    transition: opacity 150ms ease-in-out;
  }

  &__save,
  &__compare {
    --button-padding: 0;

    &:focus {
      --cp-save-opacity: 1;
      --cp-compare-opacity: 1;
    }
  }

  &__save {
    opacity: var(--cp-save-opacity, 0);
  }

  &__compare {
    opacity: var(--cp-compare-opacity, 0);
  }

  &:hover {
    --cp-save-opacity: 1;
    --cp-compare-opacity: 1;
    @include for-desktop {
      .collected-product__properties {
        display: none;
      }
    }
  }
}

.cart-mini {
  .cart-item-list {
    display: flex;
    padding: 30px 50px;
    border-bottom: 1px solid #ededed;
    margin: 0;
    &::after {
      display: none !important;
    }
    &:last-child {
      margin-bottom: 0;
    }

    .cart-item-media {
      width: 114px;
      min-width: 114px;
      img {
        mix-blend-mode: darken;
      }
    }

    .cart-item-details {
      display: block;
      padding-left: 20px;
      width: 100%;
      @media (max-width: 768px) {
        padding-left: 15px;
      }

      > div,
      > h3 {
        height: max-content;
      }

      .cart-item-sku p {
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 20px;
        text-transform: uppercase;
      }
      a {
        text-decoration: none;
      }
      .cart-item-name {
        font-size: 13px;
        font-weight: normal;
        letter-spacing: 0;
        line-height: 17px;
        color: #161615;
        text-decoration: none;
        margin: 0 0 10px 0;
        font-family: var(--barlow-regular);
        text-transform: uppercase;
        display: block;
      }
      .cart-item-brand {
        font-size: 14px;
        display: block;
        font-weight: normal;
        letter-spacing: 0;
        line-height: 17px;
        color: #161615;
        text-decoration: none;
        margin: 0 0 5px 0;
        font-family: var(--barlow-semibold);
        text-transform: uppercase;
      }

      .cart-item-price {
        padding-top: 18px;
        margin-bottom: 15px;
        font-family: 'Teko';
        text-align: right;
        display: flex;
        justify-content: flex-end;

        > p {
          display: inline-block;
          text-align: right;
          font-size: 28px;
          font-weight: normal;
          letter-spacing: 0;
          line-height: 34px;
          color: #3e3e3a;
        }

        .cart-item-price__old {
          text-decoration-line: line-through;
          color: #dd380d;
          font-size: 18px;
          margin-right: 60px;
          order: -1;
          @media (max-width: 768px) {
            margin-right: 20px;
          }
        }

        .cart-item-price__special,
        p:first-child {
          text-decoration-line: none;
        }

        .cart-item-price__special:empty {
          color: red;
          padding-right: 0;
        }
      }

      .cart-item-remove {
        display: flex;
        a {
          color: #9d9d9d;
          font-size: 11px;
          text-transform: uppercase;
          font-family: var(--barlow-regular);
          margin-left: auto;
          cursor: pointer;
          &:hover {
            color: #000;
          }
        }
      }

      .cart-item-button {
        display: block;
        flex-wrap: wrap;
      }
    }
  }

  @media (max-width: 1023px) {
    .cart-item-list {
      .cart-item-details {
        .cart-item-sku p {
          font-size: 15px;
          line-height: 16px;
        }

        .cart-item-name {
          font-size: 18px;
          line-height: 24px;
        }

        .cart-item-price {
          padding-top: 5px;
          margin-bottom: 5px;
          > p {
            font-size: 28px;
          }
        }
        .cart-item-button {
          max-width: 300px;
          flex-wrap: nowrap;
          margin-left: auto;
        }
      }
    }
  }
}
.sf-sidebar__aside {
  .sf-notification {
    max-width: 387px;
  }
  .sf-sidebar__top {
    h2 {
      margin: 0;
      color: #0f0f0f;
      font-family: var(--barlow-regular);
      font-size: 30px;
      line-height: 36px;
      text-transform: uppercase;
    }
  }
}
</style>

<style lang="scss">
.cart-mini {
  position: relative;
  z-index: 100;
  .sfprice-discount {
    color: #e50000;
    font-size: 16px;
    line-height: 20px;
    font-family: var(--barlow-regular);
    margin-left: 8px;
  }
  .sf-loader {
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 11;
    position: absolute;
    left: 0;
    background: rgba(0, 0, 0, 0.1);
    .sf-loader__overlay {
      background: none;
    }
  }
  .cart-sidebar__go-to-cart {
    margin-bottom: 1rem;
  }
}

.cart-mini .sf-header__logo {
  display: flex;
}

.cart-mini .sf-image--wrapper {
  width: 100%;
}

.cart-mini .sf-image--wrapper.empty-cart__image {
  margin: 0;
}

.cart-mini .cart-item-media img {
  height: auto;
  width: 100%;
  max-width: 150px;
  max-height: 150px;
}

.cart-mini p {
  margin: 0;
}

.cart-mini figure {
  display: flex;
  margin: 0;
  width: 100%;
  align-items: center;
}

.cart-mini .sf-collected-product__actions {
  display: none;
}

.cart-mini {
  padding-left: 0;
  padding-right: 0;
}

.cart-mini .sf-sidebar__top {
  padding: 0;
}

.cart-mini .cart-summary {
  align-items: center;
}

.cart-mini .sf-sidebar__content {
  padding: 0 0;
}

.cart-mini .sf-collected-product {
  display: flex;
  flex-wrap: nowrap;
  padding: 0;
  margin-bottom: 24px;
}

.cart-mini .sf-collected-product__quantity-wrapper {
  position: static;
  height: auto;
  padding: 0;
  margin-bottom: 30px;
  .sf-quantity-selector {
    background: #f8f8f8;
    height: auto;
    align-items: start;
    input {
      background: #f8f8f8;
      height: auto;
    }
  }
}

.cart-mini
  .sf-collected-product__quantity-selector
  .sf-quantity-selector__button {
  font-weight: 300;
  width: 30px;
  padding: 0;
  font-size: 33px;
  line-height: 43px;
  height: auto;
  font-family: 'Teko';
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0f0f0f;
  height: 30px;
}

.cart-mini .sf-icon {
  margin: auto;
}

.cart-mini .sf-input {
  width: 40px;
  padding: 0 5px;
  font-size: 14px;
  line-height: 17px;
  color: #000;
  font-family: var(--barlow-semibold);
  min-height: inherit;
  height: 30px;
  border: solid 1px #161615;
}
.cart-mini ~ .notifications .sf-notification__message {
  color: #0f0f0f;
  padding: 0 30px 0 10px;
}

.cart-mini .notifications .sf-button {
  padding: 10px 30px;
  height: auto;
  font-family: var(--barlow-semibold);
  background: #161615;
  &:hover {
    background: #2c2c2b;
  }
}

.cart-mini .sf-sidebar__bottom .sf-button.color-secondary:hover,
.cart-mini .sf-sidebar__bottom .sf-button.color-secondary:active,
.cart-mini .sf-sidebar__bottom .sf-button.color-secondary:focus,
.cart-mini .sf-sidebar__bottom .sf-button.color-primary:hover,
.cart-mini .sf-sidebar__bottom .sf-button.color-primary:active,
.cart-mini .sf-sidebar__bottom .sf-button.color-primary:focus,
.cart-mini .notifications .sf-button:hover,
.cart-mini .notifications .sf-button:active,
.cart-mini .notifications .sf-button:focus {
  text-decoration-line: none;
}

.cart-mini .cart-item-list .sf-property {
  margin-right: 10px;
  .sf-property__name {
    color: #9d9d9d;
    font-size: 11px;
    line-height: 13px;
    font-family: var(--barlow-regular);
    margin-right: 5px;
    text-transform: uppercase;
  }
  .sf-property__value {
    color: #161615;
    font-size: 11px;
    line-height: 13px;
    text-transform: uppercase;
    font-family: var(--barlow-regular);
  }
}
.cart-mini .cart-item-configuration {
  display: flex;
  padding: 0px 0 15px 0;
  font-family: var(--barlow-regular);
}
.cart-mini .sf-input input {
  font-size: 16px;
  font-weight: 500;
  padding: 0;
  font-family: var(--barlow-regular);
  color: #000;
}

.cart-mini .sf-input__error-message {
  display: block;
  left: -72px;
  min-width: 210px;
  position: absolute;
  width: 100%;
  font-size: 16px;
  @media (max-width: 768px) {
    min-width: inherit;
    left: 0;
    padding: 0 25px;
  }
}

.cart-mini .sf-input input:focus-visible {
  outline: 0;
  border: 0;
}

.cart-mini .empty-cart__heading .sf-heading__title.h2 {
  color: #000;
  font-family: var(--barlow-regular);
}
.sf-heading__description {
  font-family: var(--barlow-regular);
  font-size: 18px;
  line-height: 20px;
}

.cart-mini .notifications .sf-icon {
  margin-right: 20px;
}

.cart-mini .notifications .sf-icon svg {
  --icon-color: #212a24;
}

.cart-mini .sf-notification__message,
.cart-mini .sf-notification__title {
  color: #fff;
}

.cart-mini .sf-sidebar__aside {
  height: 100%;
}

.sf-badge--number {
  background-color: #000;
  height: 15px;
  width: 15px;
  font-size: 9px;
  color: #fff;
  font-weight: 700;
  line-height: 13px;
  position: absolute;
  right: -8px;
  font-family: 'Lato';
  top: -6px;
  bottom: inherit;
  left: inherit;
  font-size: 8px;
  justify-content: center;
  min-height: 15px;
  min-width: 15px;
  align-items: center;
  display: flex;
}

.cart-mini .button-go-to-cart {
  margin-top: 15px;
  background: #fff;
  border-color: #000;
  color: #000;
  font-size: 16px;
  font-family: var(--barlow-regular);
  span {
    color: #fff;
    font-size: 16px;
    font-family: var(--barlow-regular);
    font-weight: normal;
    &::after {
      display: none;
    }
    &.sf-property__name {
      margin: 0;
    }
    &.sf-property__value {
      &::after {
        content: ']';
        display: inline-block;
      }
      &::before {
        content: '[';
        display: inline-block;
      }
    }
  }
  &:hover {
    background: #000;
    color: #fff;
  }
}
.cart-mini .sf-sidebar__aside {
  padding: 0;
  background-color: #ffff;
  @media (max-width: 768px) {
    padding: 0;
  }
}
.cart-mini {
  .sf-sidebar__bottom {
    padding: 50px;
    .sf-btn-blue {
      border: solid 1px #9d9d9d;
      background: #fff;
      color: #161615;
      font-size: 16px;
      font-family: var(--barlow-regular);
      padding: 18px 10px;
      height: auto;
    }
    a {
      text-decoration: none;
      .button-go-to-cart {
        background: #161615;
        color: #fff;
        border: solid 1px #161615;
        span {
          margin-left: 8px;
        }
      }
    }
  }
}
@media (min-width: 1024px) {
  .cart-mini .sf-sidebar__aside {
    width: 430px;
  }

  .cart-mini .sf-collected-product {
    max-width: 100%;
  }

  .cart-mini .sf-collected-product__configuration {
    justify-content: flex-start;
    padding-bottom: 50px;
  }

  .cart-mini .header-mini-cart {
    display: none;
  }

  .cart-mini .sf-sidebar__bottom .sf-button.color-primary {
    max-width: 439px;
  }
}

@media (max-width: 1023px) {
  .cart-mini .cart-item-content div {
    height: max-content;
    line-height: 0;
  }

  .cart-mini .sf-collected-product__quantity-selector {
    height: 40px;
  }

  .cart-mini .sf-collected-product__quantity-selector button {
    font-size: 30px;
    flex: 30%;
  }

  .cart-mini .sf-collected-product__quantity-selector .sf-input {
    flex: 40%;
    min-height: auto;
    height: 100%;
  }

  .cart-mini .sf-sidebar__top .cart-summary.desktop-only {
    display: block !important;
  }

  .cart-mini .cart-summary {
    border-bottom: 0;
    margin: 0;
  }

  .cart-mini .sf-sidebar__top {
    padding-bottom: 0;
  }

  .cart-mini .sf-bar__icon {
    display: none;
  }

  .cart-mini .sf-bar {
    background: #fff;
    border-bottom: 1px solid #e9ecf1;
    height: 54px;
  }
  .cart-mini .sf-input input {
    font-size: 18px;
    height: 100%;
    line-height: 45px;
  }

  .cart-mini .sf-sidebar__bottom .sf-button.color-secondary,
  .cart-mini .sf-sidebar__bottom .sf-button.color-primary {
    letter-spacing: 0;
    height: 55px;
  }

  .cart-mini .notifications {
    right: 20px;
    margin: 0;
    top: 20px;
    transform: inherit;
  }

  .cart-mini .sf-header__item_right {
    display: none;
  }

  .cart-mini .header-mini-cart {
    left: 0;
    position: absolute;
    top: -14px;
  }

  .cart-mini .header-mini-cart .sf-header-custom_container {
    position: absolute;
    top: -47px;
  }

  .cart-mini #overlay_menu,
  .cart-mini #mega_menu {
    top: 0;
  }
}

@media (max-width: 767px) {
  .cart-mini .header-mini-cart {
    top: -13px;
  }

  .cart-mini .my-cart .cart-item-list {
    flex-wrap: nowrap;
  }
}
</style>
