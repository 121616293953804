<template>
  <div>
    <!--    <div v-if="checkSticky" class="header-placeholder"></div>-->
    <div
      class="sf-header-custom_container"
      :class="[
        { 'header--sticky': checkSticky },
        { 'header--transparent': headerColor === 'transparent' },
        { 'header--lower-index': sidebarOpen },
      ]"
    >
      <TopBar v-if="topbarData" :data="topbarData" />
      <TopBar v-else-if="topbarDataDefault" :data="topbarDataDefault" />
      <div
        class="sf-header--has-navigation-inside"
        :class="{ 'header-on-top': isSearchOpen }"
      >
        <div class="sf-header-custom">
          <div class="sf-header__item_left">
            <client-only>
              <HeaderNavigation
                v-if="$device.isDesktop"
                :menu="checkedMenu"
                :menu-images-content="menuImagesContent"
              />
              <HeaderNavigationMobile
                v-if="!$device.isDesktop"
                :menu="checkedMenu"
                :header-color="headerColor"
                :menu-images-content="menuImagesContent"
              />
            </client-only>
            <HeaderNavigation
              :menu="checkedMenu"
              :menu-images-content="menuImagesContent"
              v-show="false"
            />
          </div>
          <div
            class="sf-header__item_center"
            :class="{ 'open-searchbar': isSearchBarOpen }"
          >
            <HeaderLogo :header-color="headerColor" />
          </div>
          <div class="sf-header__item_right">
            <HeaderIconsDesktop
              :cart-total-items="cartTotalItems"
              :wishlist-total-items="wishlistTotalItems"
              @cartSidebarClick="toggleCartSidebar"
              @wishlistSidebarClick="toggleWishlistSidebar"
              :header-color="headerColor"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  ref,
  defineComponent,
  onMounted,
  useFetch,
  useRoute,
} from '@nuxtjs/composition-api';
import HeaderNavigation from '~/components/Header/Navigation/HeaderNavigation.vue';
import {
  useMarketsComposable,
  useUiHelpers,
  useUiState,
  useWindow,
  useWishlist,
  useCart,
  useContent,
  contentGetters,
} from '~/composables';
import {
  useCategorySearch,
  useFacet,
  useMenu,
  useUser,
} from '@gemini-vsf/composables';
import HeaderLogo from '~/components/Header/HeaderLogo.vue';
import { clickOutside } from '@storefront-ui/vue/src/utilities/directives/click-outside/click-outside-directive';
import debounce from 'lodash.debounce';
import HeaderIconsDesktop from '~/components/Header/HeaderIcons/HeaderIconsDesktop.vue';
import { useTopBar } from './Header/TopBar/useTopBar';
import HeaderNavigationMobile from './Header/Navigation/HeaderNavigationMobile.vue';
import TopBar from './TopBar.vue';
import { wishlistGetters } from '@gemini-vsf/composables';
import { menuHelpers } from '~/helpers/menu/menuHelpers';

export default defineComponent({
  components: {
    HeaderIconsDesktop,
    HeaderNavigation,
    HeaderLogo,
    HeaderNavigationMobile,
    TopBar,
  },
  directives: { clickOutside },
  props: {
    checkSticky: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const route = useRoute();
    const { currentMarket } = useMarketsComposable();
    const { blocks, loadBlocks } = useContent();
    const { filterMenuCallbackFn } = menuHelpers();
    const topbarData = ref();
    const topbarDataDefault = ref();
    const {
      toggleCartSidebar,
      toggleWishlistSidebar,
      toggleSearchBar,
      isMobileMenuOpen,
      isFilterSidebarOpen,
      isCartSidebarOpen,
      isWishlistSidebarOpen,
    } = useUiState();

    const { isDesktop, handleBodyClass } = useWindow();
    const sidebarOpen = computed(
      () =>
        !!(
          isFilterSidebarOpen.value ||
          isCartSidebarOpen.value ||
          isWishlistSidebarOpen.value
        )
    );
    const { setTermForUrl, getCatLink, getFacetsFromURL } = useUiHelpers();
    const { isAuthenticated } = useUser();
    const { load: loadCart, cart } = useCart();
    const { menu, search: searchMenu } = useMenu('common');
    const term = ref(getFacetsFromURL().term);
    const result = ref(null);
    const {
      result: searchResult,
      search: productsSearch,
      // loading: productsLoading,
    } = useFacet('AppHeader:Products');
    const { search: categoriesSearch } = useCategorySearch(
      'AppHeader:Categories'
    );
    const isSearchBarOpen = ref(false);

    const { hasCurrencyToSelect, hasStoresToSelect } = useTopBar();

    //Wishlist
    const { wishlist } = useWishlist();
    const wishlistItems = computed(() =>
      wishlistGetters?.getItems(wishlist?.value)
    );

    const wishlistTotalItems = computed(() => wishlistItems?.value?.length);

    const isSearchOpen = ref(false);
    const productSearchResults = ref(null);
    const accountIcon = computed(() =>
      isAuthenticated.value ? 'profile_fill' : 'profile'
    );

    const headerColor = computed(() =>
      !props.checkSticky &&
      route.value?.meta?.headerColor &&
      !isMobileMenuOpen.value
        ? route.value.meta.headerColor
        : 'white'
    );

    const menuImagesContent = ref();

    useFetch(async () => {
      await searchMenu({ code: 'navigationmenu' });
      if (currentMarket.value?.code) {
        try {
          const topbarDefaultSelector = `topbar-${currentMarket.value.localeValue}`;
          const topbarSelector = `topbar-${currentMarket.value.code}`;
          const topbarMultipleDefaultSelector = `topbarmultiple-${currentMarket.value.localeValue}`;
          const topbarMultipleSelector = `topbarmultiple-${currentMarket.value.code}`;
          const menuImagesSelector = `menu-imgs-${currentMarket.value.localeValue}`;
          await loadBlocks({
            identifiers: [
              topbarDefaultSelector,
              topbarSelector,
              topbarMultipleDefaultSelector,
              topbarMultipleSelector,
              menuImagesSelector,
            ],
          });
          const blockData = await contentGetters.getCmsBlockContent(
            blocks.value
          );
          topbarData.value =
            blockData[topbarMultipleSelector] ?? blockData[topbarSelector];
          topbarDataDefault.value =
            blockData[topbarMultipleDefaultSelector] ??
            blockData[topbarDefaultSelector];

          // duplicate topbarData.value inside itself just for development purposes
          // topbarData.value = [...topbarData.value, ...topbarData.value];

          menuImagesContent.value = blockData[menuImagesSelector];
        } catch {
          console.warn('Unable to fetch block content - AppHeader');
        }
      }
    });

    onMounted(async () => {
      if (isDesktop.value) {
        await loadCart();
      }
    });

    const closeSearch = () => {
      if (!result) {
        if (!isSearchOpen.value) return;

        term.value = '';
        isSearchOpen.value = false;
      }
    };

    // FixMe: handle search results
    const handleSearch = debounce(async (parameterValue) => {
      handleBodyClass('addClass', 'loading_search');
      term.value = parameterValue.target
        ? parameterValue.target.value
        : parameterValue;

      await Promise.all([
        productsSearch({
          itemsPerPage: 12,
          term: term.value,
        }),
        categoriesSearch({ filters: { name: { match: `${term.value}` } } }),
      ]);

      isSearchOpen.value = true;
      result.value = {
        products: searchResult.value?.data?.items,
        categories: {},
        // categories: categories.value.map((element) => categoryGetters.getCategoryTree(element)),
      };
      handleBodyClass('removeClass', 'open_menu');
      handleBodyClass('removeClass', 'loading_search');
    }, 300);

    const handleSearchBar = () => {
      toggleSearchBar();
      isSearchBarOpen.value = !isSearchBarOpen.value;
    };

    const topbarDataUrls = computed(() => {
      return {
        localized:
          topbarData.value?.data?.link?.url ||
          topbarDataDefault.value?.data?.link?.url ||
          '',
        default: topbarDataDefault.value?.data?.link?.url || '',
      };
    });

    const checkMenuChildren = (children, logged) => {
      return (
        children
          ?.filter((child) =>
            filterMenuCallbackFn(child.label, currentMarket.value.name)
          )
          .map((child) => ({
            ...child,
            label: child.label.split('---')[0],
            children:
              child.children?.length > 0
                ? checkMenuChildren(child.children, logged)
                : [],
          })) || []
      );
    };

    const checkedMenu = computed(() => ({
      ...menu.value,
      items:
        menu.value?.items?.length > 0
          ? checkMenuChildren(menu.value.items, isAuthenticated.value)
          : [],
    }));

    return {
      accountIcon,
      cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),
      menu,
      checkedMenu,
      topbarData,
      isAuthenticated,
      isSearchOpen,
      productSearchResults,
      hasCurrencyToSelect,
      hasStoresToSelect,
      handleSearch,
      term,
      isSearchBarOpen,
      getCatLink,
      setTermForUrl,
      toggleCartSidebar,
      toggleWishlistSidebar,
      closeSearch,
      handleSearchBar,
      headerColor,
      wishlistTotalItems,
      sidebarOpen,
      topbarDataDefault,
      topbarDataUrls,
      menuImagesContent,
    };
  },
});
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
html.open_menu_html {
  overflow: hidden;
}
body.open_menu {
  overflow: hidden;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: relative;
  #CookiebotWidget,
  iframe {
    z-index: 2 !important;
  }
}
body.loading_search {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0.8;
  position: relative;
  top: 0;
  left: 0;
}
body.loading_search::before {
  content: '';
  top: 50%;
  left: calc(50% - 1.25rem);
  width: 2.5rem;
  height: 2.5rem;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #000;
  animation: rotate 0.8s infinite linear;
  -webkit-animation: rotate 0.8s infinite linear;
  transform: translate(-50%, -50%);
  position: fixed;
  z-index: 9999;
  @media screen and (max-width: 1023px) {
    z-index: 99999999;
  }
}
.sf-header-custom_container {
  margin: 0 auto;
  border-bottom: 0.5px solid #dedede;
  @include from-desktop-min {
    height: 4.5rem;
  }
  will-change: transform;
  width: 100%;
  max-width: 100vw;
  z-index: 4;
  position: fixed;
  @include to-tablet-max {
    background: #ffffff;
  }
  background: var(--c-white);

  .nav-item {
    @include paragraph-small;
  }

  &.header--transparent {
    background: transparent;
    .nav-item {
      color: var(--c-white);
    }
  }
  &.header--sticky {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
  }
  .header-on-top {
    z-index: 3;
  }
  &.header--lower-index {
    z-index: 2;
  }
  .sf-header--has-navigation-inside {
    height: var(--header-height);

    @include to-tablet-max {
      height: 3.1875rem;
    }
  }
  .cart-badge {
    position: absolute;
    bottom: 40%;
    left: 40%;
  }
}
/*.header-placeholder {
  display: block;
  height: 88px;
}*/

.sf-header-custom {
  align-content: center;
  display: grid;
  grid-template-columns: 40% 20% 40%;
  grid-template-rows: 3.75rem;
  //gap: 2.273rem;
  margin: 0 1.563rem;
  height: 100%;
  @include to-tablet-max {
    height: 3.1875rem;
    grid-template-rows: 3.1875rem;
    margin: 0 1.25rem;
  }
}
#header_container {
  .sf-overlay {
    display: none;
  }
}
#layout #home {
  overflow: inherit;
}
.sf-header__item_ {
  &left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 3.182rem;
    @include from-desktop-min {
      margin-left: 2.5rem;
    }
    .sf-header__action {
      display: block;
      margin: 0;
      height: 100%;
    }
  }
  &center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.sf-header__cart,
.sf-header__user {
  margin: 0;
}
.sf-header__item_right .sf-image--wrapper {
  display: block;
}
.sf-header__user-image {
  img {
    width: 1.625rem;
    object-fit: contain;
  }
}
.sf-header__wishlist-image {
  img {
    width: 1.688rem;
    object-fit: contain;
  }
}
.sf-header__cart-image {
  img {
    // width: 23px;
    object-fit: contain;
  }
}
.item_icon {
  margin-right: 1.563rem;
  img {
    display: block;
  }
}
.item_icon:last-child {
  margin-right: 0;
}
.sf-header__action_menu {
  margin-right: 2.625rem;
}
.mega_top {
  display: flex;
  align-items: center;
  margin-bottom: 4.25rem;
  @include for-desktop {
    display: block;
  }
  .container {
    display: none;
  }
  .close_menu {
    @include for-desktop {
      float: right;
    }
    .sf-header__action_menu {
      @include for-desktop {
        margin-right: 0;
      }
    }
  }
}
.heading h3 {
  font-size: 1.75rem;
  color: #222a24;
  line-height: 2.313rem;
  padding-bottom: 1.063rem;
  font-weight: 400;
}
.content {
  padding: 0;
  list-style: none;
  margin-top: 0.313rem;
  display: none;
  padding-left: 1.875rem;
  a {
    font-size: 0.75rem;
    line-height: 1.375rem;
    color: #222a24;
    font-weight: 300;
    display: inline-block;
  }
}
.locales_custom {
  .sf-list {
    flex-direction: row-reverse;
  }
}
@media (max-width: 767px) {
  .sf-header__wishlist-image {
    display: none;
  }
}
@media screen and (min-width: 64rem) {
  .sf-search-bar {
    margin: 0 1.5rem 0 0;
  }
}

@include from-desktop-min {
  .sf-header-custom_container {
    height: calc(var(--header-height) + var(--topbar-height));
    transition: height 200ms ease-out;
    &.header--sticky {
      height: calc(var(--header-height) + var(--topbar-height));
    }
    .sf-header-custom {
      display: grid;
      grid-template-columns: 40% 20% 40%;
      grid-template-rows: 100%;
      width: 100%;
      @include desktop-boxed;
      margin: 0 auto;
      padding: 0.313rem 0.938rem;
      height: inherit;
    }
    .sf-header__item_ {
      &left {
        max-width: unset;

        .sf-header__action {
          display: block;
          margin: 0;
        }
      }
      &right {
        margin-right: 3.125rem;

        .container .sf-list {
          display: none;
        }
      }
    }
    .mega_top .container {
      display: block;
      flex-shrink: 0;
    }
    .sf-header-custom_container {
      transform: translateY(46px);
    }
    .sf-header-custom {
      padding: var(--mobile-margin);
    }
    .sf-header__mail {
      display: none;
    }
    .banner-home {
      margin-top: -70px;
    }
    .sf-header__item_left {
      .sf-header__action {
        --header-action-margin: 0;
      }
    }
    .mega_top {
      margin-bottom: 33px;
    }
    .close_menu {
      .sf-image {
        width: 17px;
        height: 17px;
        object-fit: contain;
      }
    }
    .mobile-only {
      display: none !important;
    }
  }
  /*  .header-placeholder {
    display: block;
    height: 6.25rem;
  }*/
}
@include from-desktop-min {
  .sf-header-custom_container {
    max-width: var(--desktop-l-min);
  }
}
</style>
