var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"geolocator-modal-container"},[_c('SfModal',{staticClass:"geolocator-modal",attrs:{"persistent":true,"visible":_vm.isGeoLocatorModalOpen},on:{"close":function () {
        _vm.updateLocalData(true);
        _vm.toggleGeoLocatorModal();
      }},scopedSlots:_vm._u([{key:"close",fn:function(){return [_c('CrossIcon')]},proxy:true},{key:"modal-bar",fn:function(){return undefined},proxy:true}])},[_vm._v(" "),_vm._v(" "),_c('div',{staticClass:"geolocator-modal-content"},[_c('div',{staticClass:"geolocator-modal-body"},[_c('div',{staticClass:"geolocator-modal-text-top"},[_c('p',[_c('span',{staticClass:"geolocator-modal-text__text"},[_vm._v("\n              "+_vm._s(_vm.$t('You seem to be connected from '))+"\n            ")]),_vm._v(" "),_c('span',{staticClass:"geolocator-modal-text__text--bold"},[_vm._v("\n              "+_vm._s(_vm.$t(("" + _vm.countryLabel)))+"\n            ")])]),_vm._v(" "),(_vm.countryFlag)?_c('nuxt-img',{attrs:{"src":_vm.countryFlag,"format":"webp","width":"20px","height":"13px","alt":"flag","loading":"lazy"}}):_vm._e()],1),_vm._v(" "),_c('p',{staticClass:"geolocator-modal-text__text"},[_vm._v("\n          "+_vm._s(_vm.$t('Do you wish to switch country?'))+"\n        ")])]),_vm._v(" "),_c('div',{staticClass:"geolocator-modal-bottom"},[(_vm.countryCode === _vm.locale)?_c('CustomButton',{attrs:{"layout":"first"},on:{"click":function () {
              _vm.updateLocalData(true);
              _vm.toggleGeoLocatorModal();
            }}}):_c('a',{staticClass:"geolocator-modal-cta-accept",attrs:{"href":("/" + _vm.localePathString)},on:{"click":function () {
              _vm.updateLocalData(true);
              _vm.toggleGeoLocatorModal();
            }}},[_vm._v("\n          "+_vm._s(_vm.$t('Accept suggestion'))+"\n        ")]),_vm._v(" "),_c('CustomButton',{attrs:{"layout":"second"},on:{"click":function () {
              _vm.updateLocalData(false);
              _vm.toggleGeoLocatorModal();
              _vm.toggleCountryModal();
            }}},[_vm._v("\n          "+_vm._s(_vm.$t('Choose manually'))+"\n        ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }