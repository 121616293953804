<template>
  <div id="error" class="page-404">
    <h1 :style="{ display: 'none' }">{{ $t('Not found') }}</h1>
    <div class="title-404">
      <h2>{{ $t('oops!') }}</h2>
    </div>
    <div class="body-404">
      <h2>{{ $t('The page you were looking for cannot be found') }}</h2>
      <p>
        {{
          $t(
            'If you typed the URL directly, please make sure the spelling is correct. If you clicked on a link to get here, the link is outdated.'
          )
        }}
      </p>
      <p>
        {{
          $t('You can also click the following links to get you back on track!')
        }}
      </p>
    </div>
    <div class="button-404">
      <CustomButton :link="'/'" layout="second">
        {{ $t('Homepage') }}
      </CustomButton>
      <CustomButton
        :link="locale.includes('it') ? '/contatti' : '/contacts'"
        layout="second"
      >
        {{ $t('Contact us') }}
      </CustomButton>
    </div>
  </div>
</template>

<script>
import {
  useMeta,
  useRouter,
  useContext,
  defineComponent,
} from '@nuxtjs/composition-api';
import { useI18n } from '~/helpers/hooks/usei18n';
import CustomButton from '~/components/General/FormElements/CustomButton.vue';
import { onMounted } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ErrorLayout',
  // Forcing layout change on page leave.
  layout: 'errorLayout',
  components: { CustomButton },
  props: {},
  head: {},
  setup() {
    const router = useRouter();
    const { app } = useContext();
    const { locale } = useI18n();

    const cookiebot = process && process.browser && window && window.Cookiebot;

    onMounted(() => {
      if (cookiebot) {
        window.addEventListener(
          'CookiebotOnDecline',
          () => {
            if (window.CookiebotDialog) window.location.reload();
          },
          false
        );
      }
    });

    useMeta(() => ({
      title: app.i18n.t('Not found'),
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: app.i18n.t('Not found') || 'Not found',
        },
      ],
    }));

    return {
      locale,
      router,
    };
  },
});
</script>

<style lang="scss" scoped>
*:focus,
*:focus-visible {
  outline: none;
}

#error {
  padding: 165px 84px 135px 84px;
  @include desktop-boxed;
  margin: auto;
  @media screen and (max-width: 768px) {
    padding: 0 24px 50px 24px;
  }

  h1 {
    font-size: var(--h1-font-size);
  }

  h4,
  h4 span,
  strong {
    font-size: var(--h4-font-size);
  }

  p {
    line-break: anywhere;
  }

  .title-404 {
    @include desktop-h2;
    text-transform: uppercase;
  }

  .body-404 {
    @include desktop-h4;
    h2 {
      @include desktop-h3;
    }
    text-transform: uppercase;
  }
}

.page-404 {
  text-align: center;

  .button-404 {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 6rem;
    align-items: center;
  }
}
</style>
