/* eslint-disable no-secrets/no-secrets */
import { computed, useContext } from '@nuxtjs/composition-api';
import { useI18n } from '~/helpers/hooks/usei18n';
import { useUiNotification, useAtelier } from '~/composables';
import {
  useUser,
  CartItem,
  useShipping,
  useUserShipping,
  useCountrySearch,
  useShippingProvider,
  userShippingGetters,
  useCart as useCartComposable,
} from '@gemini-vsf/composables';
import { cartHelpers } from '~/helpers/cart/cartHelpers';
import defaultShippingAddress from '~/helpers/checkout/defaultShippingAddress';
import { addressFromApiToForm } from '~/helpers/checkout/address';

const useCart = () => {
  const {
    load: loadComposable,
    addItem: addItemComposable,
    removeItem: removeItemComposable,
    applyCoupon: applyCouponComposable,
    removeCoupon: removeCouponComposable,
    updateItemQty: updateItemQtyComposable,
    addNoteOnCart: addNoteOnCartComposable,
    error: errorComposable,
    cart: cartComposable,
  } = useCartComposable();
  const trans = useI18n();
  const { atelierStock, getStock: getAtelierStock } = useAtelier();
  const { isAuthenticated, load: loadUser } = useUser();
  const { send: sendNotification } = useUiNotification();
  const { moveGiftToBottomSortFn } = cartHelpers();
  const {
    $vsf: {
      $gemini: { config },
    },
    app: {
      i18n: { localeProperties: currentMarket },
    },
  } = useContext();
  const { save: setShippingAddress, error: shippingError } = useShipping();
  const { search: searchCountry, country } = useCountrySearch();
  const { shipping: userShipping, load: loadUserShipping } = useUserShipping();
  const { save: setShippingMethodOnCart } = useShippingProvider();
  const atelierCheckEnabled = config['atelierCheckEnabled'];

  const showCartError = (cartError: Error) => {
    sendNotification({
      id: Symbol('cartError'),
      type: 'info',
      icon: 'error',
      title: `${trans.t('An error occurred')}`,
      message: `${trans.t(cartError.message)}`,
    });
  };

  const sortedCart = computed(() => {
    if (!cartComposable.value || (!cartComposable.value?.items && cartComposable.value?.items.length === 0)) {
      return cartComposable.value;
    }
    const cartItemsCopy = JSON.parse(JSON.stringify(cartComposable.value?.items));
    const sortedCartItems = cartItemsCopy.sort((a: CartItem, b: CartItem) => moveGiftToBottomSortFn(a, b));
    return {
      ...cartComposable.value,
      items: sortedCartItems,
    };
  });

  const load = async () => {
    await loadUser();
    await loadComposable({
      customQuery: isAuthenticated.value
        ? {
            customerCart: 'customerCartCustom',
          }
        : {
            cart: 'cartCustom',
          },
    });
  };

  const addNoteOnCart = async (data) => {
    await addNoteOnCartComposable({
      ...data,
      customQuery: { setNotesToCart: 'setNotesToCartCustom' },
    });
  };

  const getStock = async (atelierId, sizeValueLabel, productStock) => {
    if (!atelierCheckEnabled) {
      return productStock;
    }
    if (!atelierId || !sizeValueLabel || !productStock) {
      return 0;
    }
    await getAtelierStock(atelierId, sizeValueLabel);
    return Number(productStock) < Number(atelierStock.value) ? Number(productStock) : Number(atelierStock.value);
  };

  const getConfigurableProductStock = async (product) => {
    const sizeValueLabel = product?.variants?.find((p) => p?.product?.uid === product.configurable_product_options_selection?.variant?.uid)?.product
      ?.size_attribute?.label;
    const variantStock = product?.configurable_product_options_selection?.variant?.only_x_left_in_stock;
    return await getStock(product?.atelier_id, sizeValueLabel, variantStock);
  };

  const getCartItemStock = async (item) => {
    const sizeValueLabel = item?.configurable_options?.find((e) => e.option_label === 'size' || e.option_label === 'taglia')?.value_label;
    return await getStock(item?.product?.atelier_id, sizeValueLabel, item?.product.only_x_left_in_stock);
  };

  const checkCartStocks = async (isCartPage = false) => {
    if (!atelierCheckEnabled) {
      return true;
    }
    if (!cartComposable.value?.items?.length) {
      return false;
    }
    const itemsStocks = await Promise.all(cartComposable.value.items.map(async (item) => await getCartItemStock(item)));
    const validAtelierStocks = !itemsStocks.includes(0);
    if (!validAtelierStocks) {
      const outOfStockItemsNames = [];
      itemsStocks.forEach((stock, index) => {
        if (stock === 0) {
          outOfStockItemsNames.push(cartComposable.value?.items?.[index]?.product?.name || '');
        }
      });
      sendNotification({
        id: Symbol('check_cart_stocks_fail'),
        type: 'info',
        icon: 'error',
        persist: true,
        title: '',
        message: `${trans.t(
          isCartPage
            ? 'The following items are no longer available for purchase, remove them to proceed to purchase: '
            : 'The following items are no longer available for purchase, go back to the shopping cart and remove them to proceed to purchase: '
        )}${outOfStockItemsNames.filter(Boolean).join(', ')}`,
        ...(isCartPage ? {} : { action: { text: `${trans.t('Cart')}`, link: '/cart' } }),
      });
    }
    return validAtelierStocks;
  };

  const addItem = async (item, skipAtelierCheck = false) => {
    const checkedStock = skipAtelierCheck || !atelierCheckEnabled ? Number(item.quantity) : await getConfigurableProductStock(item.product);
    if (Number(item.quantity) > Number(checkedStock)) {
      sendNotification({
        id: Symbol('product_quantity_exceeds'),
        message: `${trans.t("Product doesn't have the requested quantity")}`,
        type: 'info',
        icon: 'error',
        persist: false,
        title: '',
      });
      return false;
    } else {
      await addItemComposable({
        ...item,
        customQuery: {
          addConfigurableProductsToCart: 'addConfigurableProductsToCartCustom',
        },
      });
      if (errorComposable.value.addItem) {
        showCartError(errorComposable.value.addItem);
        return false;
      }
      return true;
    }
  };

  const removeItem = async (product) => {
    await removeItemComposable({
      product,
      customQuery: {
        removeItemFromCart: 'removeItemFromCartCustom',
      },
    });
    if (errorComposable.value.removeItem) {
      showCartError(errorComposable.value.removeItem);
      return false;
    }
    return true;
  };

  const applyCoupon = async (data) => {
    await applyCouponComposable({
      ...data,
      customQuery: {
        applyCouponToCart: 'applyCouponToCartCustom',
      },
    });
    return !errorComposable.value.applyCoupon;
  };

  const removeCoupon = async (data) => {
    await removeCouponComposable({
      ...data,
      customQuery: {
        removeCouponFromCart: 'removeCouponFromCartCustom',
      },
    });
    return !errorComposable.value.removeCoupon;
  };

  const updateItemQty = async (product, quantity, skipAtelierCheck = false) => {
    const checkedStock = skipAtelierCheck || !atelierCheckEnabled ? Number(quantity) : await getCartItemStock(product);
    if (Number(quantity) > Number(checkedStock)) {
      sendNotification({
        id: Symbol('cart_product_quantity_exceeds'),
        message: `${trans.t("Product doesn't have the requested quantity")}`,
        type: 'info',
        icon: 'error',
        persist: false,
        title: '',
      });
    } else {
      await updateItemQtyComposable({
        product,
        quantity: Number.parseInt(quantity, 10),
        customQuery: {
          updateCartItems: 'updateCartItemsCustom',
        },
      });
      if (errorComposable.value.updateItemQty) {
        showCartError(errorComposable.value.updateItemQty);
      }
    }
  };

  const setUserDefaultShippingAddress = async () => {
    if (!userShipping.value?.addresses) {
      await loadUserShipping();
    }
    const defaultAddress = userShippingGetters.getAddresses(userShipping.value, { default_shipping: true });
    const availableCountriesForLocale = currentMarket.value?.countries || [];
    if (defaultAddress?.length > 0 && availableCountriesForLocale.includes(defaultAddress[0].country_code)) {
      const addressData = defaultAddress[0];
      const shippingDetailsData = {
        ...addressFromApiToForm(addressData),
        customerAddressId: addressData.id,
      };
      await setShippingAddress({
        params: {},
        shippingDetails: shippingDetailsData,
      });
      return !shippingError.value?.save;
    }
    return false;
  };
  const setFakeDefaultShippingAddress = async () => {
    const marketFirstCountry = currentMarket?.countries?.[0];
    if (!marketFirstCountry) return false;
    await searchCountry({ id: marketFirstCountry });
    const countryFirstRegion = country.value?.available_regions?.[0]?.code;
    await setShippingAddress({
      params: {},
      shippingDetails: defaultShippingAddress(marketFirstCountry, countryFirstRegion || 'default'),
    });
    return !shippingError.value?.save;
  };
  const setDefaultShippingAddressAndMethod = async () => {
    if (!cartComposable.value || cartComposable.value?.shipping_addresses?.[0]?.selected_shipping_method) return;
    let addressSuccess = !!cartComposable.value?.shipping_addresses?.length;
    if (isAuthenticated.value && !addressSuccess) {
      addressSuccess = await setUserDefaultShippingAddress();
    }
    if (!addressSuccess) {
      addressSuccess = await setFakeDefaultShippingAddress();
    }
    if (addressSuccess) {
      await setShippingMethodOnCart({
        shippingMethod: {
          carrier_code: 'table_rate',
          method_code: 'express',
        },
      });
    }
  };

  return {
    ...useCartComposable(),
    setDefaultShippingAddressAndMethod,
    getConfigurableProductStock,
    getCartItemStock,
    checkCartStocks,
    addNoteOnCart,
    showCartError,
    updateItemQty,
    removeCoupon,
    applyCoupon,
    removeItem,
    addItem,
    load,
    cart: sortedCart,
  };
};

export default useCart;
